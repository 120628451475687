<template>
  <base-frame-setting>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <facility-room-type-calendar
        ref="calendar"
        slim
        excludeRoomTypesWithNoListedRooms
        @facilityIdChange="onFacilityIdChange"
        @roomTypeCompleted="roomTypeCompleted"
      >
        <template v-slot:default="{
          facility,
          mwCalendarCols,
          fromDate,
          toDate,
          roomType,
          completed,
          refreshCount
        }">
          <frame-setting-calendar-room-type
            :refreshCount="refreshCount"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :completed="completed"
            @inventoryClick="inventoryClick"
            :isInventorySelected="isInventorySelected"
            mode="manage"
          />
        </template>
        <template v-slot:additionalInfo>
          <span class="ml-5 pr-1">
            {{ $t("frameSetting.calendar.periodFrameRatio") }}
          </span>
          <div class="d-flex">
            <div v-for="inv in invPercentages" :key="inv.nameAbbr"
              :style="$getColorAndBackground(inv.color, true)">
              {{inv.nameAbbr}} {{inv.ratio | percent}}
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div class="modal-custom_block d-flex align-center mb-4 ml-auto" v-if="checkPerUser">
            <v-btn
              v-if="!bulkSelect"
              width="90px"
              color="primary"
              class="mr-2"
              @click="startBulk"
            >
              {{ $t("buttons.startSetting") }}
            </v-btn>
            <v-btn
              v-else
              width="90px"
              color="primary"
              class="mr-2"
              @click="endBulk"
            >
              {{ $t("buttons.cancel") }}
            </v-btn>
            <v-select
              :items="inventoryTypes"
              v-model="cellSelectInventoryTypeTo"
              item-text="nameAbbr"
              item-value="id"
              outlined
              class="v-select-custom select-h mr-2 super-dense"
              dense
              :menu-props="{contentClass: 'super-dense', maxHeight: 500}"
              :disabled="!bulkSelect"
            ></v-select>
            <v-btn
              width="90px"
              :disabled="!bulkSelect"
              color="error"
              @click="completeBulk"
            >
              {{ $t("buttons.done") }}
            </v-btn>
          </div>
        </template>
      </facility-room-type-calendar>
      <v-dialog
        v-model="dialog"
        width="300px"
        @click:outside="selectMutil = false;"
      >
        <v-form ref="form">
          <template v-slot:activator="{ on, attrs }">
            <p v-bind="attrs" v-on="on" class="calendar-dialog_item">1</p>
          </template>
          <div class="calendar-dialog_content">
            <v-select
              v-model="inventoryTypeSelect"
              :items="inventoryTypes"
              item-text="nameAbbr"
              item-value="id"
              class="calendar_event_tooltip_input mb-2"
            ></v-select>
            <v-btn
              color="primary"
              class="mb-2 mr-2 btn-"
              @click="actionSetInventory"
              :disabled="!checkPerUser || !inventoryTypeSelect"
            >
              {{ $t("buttons.change") }}
            </v-btn>
            <v-menu :close-on-content-click="false" @input="onBulkToggle" class="calendar-dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="!checkPerUser"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="calendar_event_tooltip_btn mb-2"
                  color="#3083DC"
                >
                  {{ $t("buttons.changeAll") }}
                </v-btn>
              </template>
              <div class="calendar-dialog_content calendar-dialog_card">
              <v-form ref="formChild">
                  <v-row class="calendar-dialog_group pt-2">
                  <v-col cols="6" class="pb-0 pr-2">
                    <v-row>
                      <v-col cols="4" class="pr-0">
                        <span>{{
                          $t("reservationCalendar.oldInventoryType")
                        }}</span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          :items="inventoryTypes"
                          v-model="bulkInventoryTypeFrom"
                          item-text="nameAbbr"
                          item-value="id"
                          color="var(--text__gray)"
                          outlined
                          hide-details
                          class="
                            v-select-custom
                            w-100
                            calendar-dialog_card_select
                          "
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="pl-2 pb-0">
                    <v-row>
                      <v-col cols="3" class="pr-0">
                        <span>{{
                          $t("reservationCalendar.numberFrames")
                        }}</span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          :items="listRooms"
                          v-model="rooms"
                          color="var(--text__gray)"
                          outlined
                          hide-details
                          class="
                            v-select-custom
                            w-100
                            calendar-dialog_card_select
                          "
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="pr-2 mt-3">
                    <v-row>
                      <v-col cols="4" class="pr-0">
                        <span>{{
                          $t("reservationCalendar.newInventoryType")
                        }}</span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          :items="inventoryTypes"
                          v-model="bulkInventoryTypeTo"
                          item-text="nameAbbr"
                          item-value="id"
                          color="var(--text__gray)"
                          outlined
                          :rules="reservationCalendar.newInventory"
                          class="
                            v-select-custom
                            w-100
                            calendar-dialog_card_select
                          "
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="pl-2 mt-3">
                    <v-select
                      v-model="bulkRoomTypeIds"
                      :items="roomTypes"
                      item-text="name"
                      item-value="id"
                      :label="showSelect()"
                      hide-details
                      multiple
                      outlined
                      class="
                            cus-select
                            v-select-custom
                            w-100
                            calendar-dialog_card_select
                            select-text
                            room-types-select
                          "
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip class="mt-1" x-small>
                          <span>{{ item.name }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <p class="calendar-dialog_card_title mt-3 mb-1">
                  {{ $t("reservationCalendar.scopeOfApplication") }}
                </p>
                <p
                  class="fs-9 mb-1"
                >
                  {{ bulkFromDate | jaLongDate }}から
                </p>
                <!-- Date picker -->
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div>
                      <v-icon>mdi-calendar-month</v-icon>
                      <v-btn
                        outlined
                        v-on="on"
                        small
                        class="bg--btn-white mr-5 ml-1 btn-custom-gray"
                        v-text="dateFormatted(dateToChange)"
                      ></v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    :min="bulkFromDate"
                    v-model="dateToChange"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuDate = false"
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
                <!--  -->
                <div class="d-flex" >
                  <v-checkbox
                    v-for="day in dayLists"
                    v-model="arrDay"
                    :key="day.val"
                    :label="day.text"
                    :value="day.val"
                    hide-details
                    :rules="[checkRuleDay() || 'required']"
                    class="text-size-normal mr-1"
                  />
                </div>
                <v-btn
                  color="primary"
                  class="mb-2 mr-2"
                  @click="changeInventory"
                  :loading="$apollo.loading"
                >
                  {{ $t("buttons.apply") }}
                </v-btn>
                <v-btn
                  outlined
                  class="calendar_event_tooltip_btn--dark mb-2"
                  @click="dialog = false"
                >
                  {{ $t("buttons.cancel") }}
                </v-btn>
              </v-form>
              </div>
            </v-menu>
          </div>
        </v-form>
      </v-dialog>
    </template>
  </base-frame-setting>
</template>

<script>
import moment from 'moment'
import { UPDATE_INVENTORY, SET_INVENTORY } from '@/api/graphql/inventory/inventory'
import gql from 'graphql-tag'
import FacilityRoomTypeCalendar from '@/components/FacilityRoomTypeCalendar'
import FrameSettingCalendarRoomType from './frameSettingCalendarRoomType.vue'
import BaseFrameSetting from '@/components/FrameSetting/BaseFrameSetting'
import { checkPermissionUserCurrent } from '@/utils/permissions'

function defaultArrDay () {
  return [1, 2, 3, 4, 5, 6, 0].slice()
}

export default {
  name: 'FrameSettingCalendar',
  components: {
    FacilityRoomTypeCalendar,
    FrameSettingCalendarRoomType,
    BaseFrameSetting
  },
  data () {
    return {
      facilityList: [],
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      forTimeFilterSelected: 1,
      inventoryCountsByFacility: {},
      selectRoomType: [],
      arrChangeFrom: [],
      facilities: [],
      selectMutil: false,
      rooms: null,
      inventoryTypeTo: null,
      menuDate: false,
      changDate: new Date().toISOString().substr(0, 10),
      inventoryTypeSelect: null,
      cellSelectInventoryTypeTo: null,
      bulkInventoryTypeFrom: null,
      bulkInventoryTypeTo: null,
      bulkRoomTypeIds: [],
      dialog: false,
      listRooms: [],
      arrRoomType: [],
      arrDay: defaultArrDay(),
      dateToChange: new Date().toISOString().substr(0, 10),
      dialogDate: false,
      // table
      reservationCalendar: {
        newInventory: [(v) => !!v || this.$t('rules.isRequired')]
      }
    }
  },

  computed: {
    lastClickedInventory () {
      return this.$store.getters.lastClickedInventory
    },
    bulkFromDate () {
      return this.lastClickedInventory?.inventory.stayDate ?? this.datePicker
    },
    bulkSelect () {
      return this.$store.getters.allowBulkSelect
    },
    inventoryTypes () {
      return this.$store.getters.inventoryTypes
    },
    inventorySummary () {
      const typeCount = {}
      Object.values(this.inventoryCountsByFacility).forEach(byRoomType => {
        Object.values(byRoomType).forEach(byInvType => {
          Object.keys(byInvType).forEach(invTypeId => {
            typeCount[invTypeId] = (typeCount[invTypeId] ?? 0) + byInvType[invTypeId]
          })
        })
      })
      return typeCount
    },
    invPercentages () {
      const total = this.inventorySummary.total
      const arr = this.$store.getters.inventoryCountsToArray(this.inventorySummary)
      for (const inv of arr) {
        inv.ratio = inv.count / total
      }

      return arr
    },
    dayLists () {
      return [
        { val: 1, text: '月' },
        { val: 2, text: '火' },
        { val: 3, text: '水' },
        { val: 4, text: '木' },
        { val: 5, text: '金' },
        { val: 6, text: '土' },
        { val: 0, text: '日' }
      ]
    },
    roomTypes () {
      const roomTypes = []
      this.facilities.forEach(f => {
        f.roomTypes.forEach(rt => {
          roomTypes.push({
            id: rt.id,
            name: `${f.shortName}/${rt.name}`
          })
        })
      })

      return roomTypes
    }
  },

  mounted () {
    this.getInventoryTypeList()
    this.getListDay()
  },

  watch: {
    bulkFromDate: {
      immediate: true,
      handler () {
        if (this.bulkFromDate) {
          var result = new Date(this.bulkFromDate).toISOString().substr(0, 10)
          this.dateToChange = result
        }
      }
    },
    lastClickedInventory (clicked) {
      if (clicked) {
        const { inventoryTypeId, roomTypeId } = clicked.inventory
        this.inventoryTypeSelect = inventoryTypeId
        // set the default bulk update params as per request from Tokyu
        // https://overflow.io/s/542E57PQ?node=294dfa24&thread=4237e6f5-7574-4649-b4f9-71cc1d566b41&comment=8e105bfd-f2ea-4405-ac12-ad2e3a340fab&comment=8e105bfd-f2ea-4405-ac12-ad2e3a340fab
        this.bulkInventoryTypeFrom = inventoryTypeId
        this.bulkRoomTypeIds = [roomTypeId]
        if (!this.bulkSelect && this.checkPerUser) {
          this.dialog = true
        }
      }
    }
  },

  methods: {

    refreshRoomTypes (roomTypeIds) {
      this.$refs.calendar.refreshRoomTypes(roomTypeIds)
    },
    onFacilityIdChange (newIds) {
      if (newIds) {
        Object.keys(this.inventoryCountsByFacility).forEach(key => {
          if (!newIds.includes(parseInt(key))) {
            this.$delete(this.inventoryCountsByFacility, key)
          }
        })
      } else {
        // do nothing
      }
    },
    roomTypeCompleted ({
      facilityId,
      roomTypeId,
      counts
    }) {
      let base = {
        [roomTypeId]: counts
      }
      if (this.inventoryCountsByFacility[facilityId]) {
        base = {
          ...this.inventoryCountsByFacility[facilityId],
          ...base
        }
      }

      this.$set(this.inventoryCountsByFacility, facilityId, base)
    },
    startBulk () {
      this.$store.commit('setAllowBulkSelect', true)
    },
    endBulk () {
      this.$store.commit('setAllowBulkSelect', false)
    },
    async completeBulk () {
      await this.actionSetInventory()
      this.endBulk()
    },
    async actionSetInventory () {
      let targets
      let toTypeId
      if (this.bulkSelect) {
        targets = this.$store.getters.selectedInventoryArray
        toTypeId = this.cellSelectInventoryTypeTo
      } else {
        targets = [this.lastClickedInventory]
        toTypeId = this.inventoryTypeSelect
      }
      if (targets.length && toTypeId) {
        const changeFrom = targets.map(({ inventory: inv }) => ({
          roomTypeId: inv.roomTypeId,
          date: inv.stayDate,
          inventoryTypeId: inv.inventoryTypeId
        }))
        await this.$apollo.mutate({
          mutation: gql`${SET_INVENTORY}`,
          variables: {
            inventoryTypeTo: toTypeId,
            changeFrom
          }
        })

        this.refreshRoomTypes(changeFrom.map(c => c.roomTypeId))
      }
      this.dialog = false
    },

    checkRuleDay () {
      if (this.arrDay.length > 0) {
        return true
      } else { return false }
    },

    async changeInventory () {
      if (this.$refs.formChild.validate()) {
        await this.$apollo.mutate({
          mutation: gql`${UPDATE_INVENTORY}`,
          variables: {
            data: {
              inventoryTypeFrom: this.bulkInventoryTypeFrom,
              inventoryTypeTo: this.bulkInventoryTypeTo,
              rooms: this.rooms,
              roomTypes: this.bulkRoomTypeIds,
              dateFrom: this.bulkFromDate,
              dateTo: this.dateToChange,
              daysOfWeek: this.arrDay
            }
          }
        })
        this.dialog = false
        this.refreshRoomTypes(this.bulkRoomTypeIds)
        this.$refs.formChild.reset()
        this.arrDay = defaultArrDay()
      }
    },

    showSelect () {
      if (this.bulkRoomTypeIds.length === 0) {
        return this.$t('reservationCalendar.applicableRoomType')
      }
    },

    async getInventoryTypeList () {
      await this.$store.dispatch('loadInventoryTypes')
    },

    getListDay () {
      for (let i = 1; i <= 20; i++) {
        this.listRooms.push(i)
      }
    },
    dateFormatted (value) {
      return moment(value).format('yyyy年MM月DD日')
    },
    inventoryClick (payload) {
      this.$store.commit('toggleSelectedInventory', payload)
    },
    isInventorySelected (payload) {
      return this.$store.getters.isInventorySelected(payload)
    },
    onBulkToggle (visible) {
      if (visible) {
      }
    }
  },
  apollo: {
    facilities: {
      query: gql`query {
        facilityList (loadRoomType: true) {
          shortName
          roomTypes {
            id
            name
          }
        }
      }`,
      update: (data) => data.facilityList
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .cus-select {
  label {
    left: -7px !important;
  }

  .v-select__selections {
    margin-left: -15px;
    margin-right: -20px;

    input {
      display: none;
    }
  }
  .v-input__slot .v-select__slot {
    height: unset;
  }
}
::v-deep{
  .v-input__control{
    .v-select__selections{
      .v-select__selection--comma{
        font-size: 15px !important;
        color: #212121 !important;
      }
    }
  }
  .v-btn.v-size--default{
    font-size: 16px !important;
  }
  .calendar_event_tooltip_btn{
    color: #3083DC !important;
    border: 1px solid #3083DC !important;
  }
  .calendar-dialog_card{
    max-width: 450px;
    span{
      font-size: 12px;
    }
  }
  .v-select-custom{
    .v-select__selections{
      .v-select__selection--comma{
        font-size: 11px !important;
        color: #aaa !important;
      }
    }
    .v-label{
      color: #aaa !important;
    }
  }
  .calendar-dialog_card_title{
    font-size: 10px !important;
    font-weight: bold;
  }
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled){
    font-size: 11px !important;
  }
  .v-list-item__title{
    font-size: 11px !important;
  }
  .v-simple-checkbox{
    .v-icon__svg{
      height: 20px !important;
      width: 20px !important;
    }
  }
  .btn-custom-gray{
    color: #AAAAAA !important;
    font-size: 11px !important;
  }
  .mdi-calendar-month{
    color: #616161 !important;
  }
  .v-input--selection-controls__input{
    .v-label {
      color: #AAAAAA !important;
      font-size: 12px !important;
    }
  }
  .v-input--checkbox{
    .v-label{
      font-size: 12px !important;
    }
  }
  .primary{
    .v-btn__content{
      font-size: 16px !important;
    }
  }
  .calendar_event_tooltip_btn--dark{
    border: 1px solid #BDBDBD !important;
      .v-btn__content{
        font-size: 16px !important;
        color: #616161 !important;
      }
  }
}

.room-types-select {
  height: unset;
  min-height: 28px;
}
</style>
